import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, batch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Search } from "react-feather";
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DrawerNavigator from "../components/DrawerNavigator";
import { additionCartMontant, formatNumber } from "../functions/General";
import Grid from '@mui/material/Grid';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ItemProduct from "../components/ItemProduct";
import ItemProductDesktop from "../components/ItemProductDesktop";
import ModalDetailProduct from "../components/ModalDetailProduct";
import ModalLogin from "../components/ModalLogin";
import ModalRating from "../components/ModalRating";
import SliderPrice from "../components/SliderPrice";
import CategoryItemDesktop from "../components/CategoryItemDesktop";
import { useScreenDetector } from "../hooks/useMediaQuery";
import ModalDetailDesktopProduct from "../components/ModalDetailDesktopProduct";
import ModalDesktopLogin from "../components/ModalDesktopLogin";
import ModalDesktopRating from "../components/ModalDesktopRating";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { _saveRating, _findUserUserStoreProductRating, _updateProductRating, _updateProductStockRating, _findUserProductRatingByStockId } from "../functions/requestRating";
import { ADD_CART, UPDATE_STOCK_PRODUCT, ADD_USER_STORE_RATING, UPDATE_CART, REFRESH_VIEW, GET_USER_COORDINATES } from "../lib/actions/action_type";
import '../App.css';

const Home = () => {

  const dispatch=useDispatch();
  const history=useHistory();
  const [openDrawer, setOpenDrawer]=useState(false);
  const [openDetailModal, setOpenDetailModal]=useState(false);
  const stock_product=[...useSelector(state => state.product_list)];
  const categoryData=[...useSelector(state => state.category_list)];
  const data_store=[...useSelector(state => state.store_data)];
  const [loading, setLoading]=useState(false);
  const data_user_store=[...useSelector(state => state.list_user_store)];
  const cartData=[...useSelector(state => state.cart_list)];
  const user_data_infos=[...useSelector(state => state.list_user_infos)];
  let _isRefresh=useSelector(state => state.isRefresh);
  const [data_product, setDataProduct]=useState([]);
  const [other_data_product, setOtherDataProduct]=useState([]);
  const [category_product, setCategoryProduct]=useState([]);
  const [checkCategory, setCheckCategory]=useState(null);
  const [id_display_product, setIdDisplayProduct]=useState(0);
  const [openModal, setOpenModal]=useState(false);
  const [openDetailModalRating, setOpenDetailModalRating]=useState(false);
  const [typePage, setTypePage]=useState("");
  const [filterPage, setFilterPage]=useState("Tri par défaut");
  const { isMobile, isTablet, isDesktop } = useScreenDetector();

  const array_filter_menu=[
    { id:1, name_cat:"Le prix le plus élevé" },
    { id:2, name_cat:"Le prix le plus bas" },
    { id:3, name_cat:"Les mieux notés" }
  ]

  useEffect(()=>{
    let _listCategory=[...categoryData];
    if(user_data_infos && user_data_infos[0].id_order_category_product){
        let _tabIndexCategory = (user_data_infos[0].id_order_category_product).split(",").map(item=>item);
        _listCategory=_listCategory.sort((a, b) => _tabIndexCategory.indexOf(a.id_category) - _tabIndexCategory.indexOf(b.id_category));
    } 
    _listCategory.unshift({id_local_category:0, nom_category:"Tout", color_category:"#30d158"});
    _listCategory = _listCategory.map((item)=>{
        return item.id_local_category==0 ? { ...item, isSelect:true, tag:"all" } : { ...item, isSelect:false, tag:"other" }
    });
    let _listProduct=stock_product.filter(item=>item.id_category.indexOf(parseInt(_listCategory[0].id_local_category))>-1);
    setCheckCategory(_listCategory[0].id_local_category);
    setDataProduct(_listProduct);
    setOtherDataProduct(_listProduct);
    setCategoryProduct(_listCategory);
    history.replace("/");
  }, []);

  useEffect(()=>{
    const options = {
      enableHighAccuracy: true,
      timeout: 10000,
      maximumAge:Infinity
    };
    navigator?.geolocation.getCurrentPosition(success, error, options);
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const success=({coords: {latitude: lat, longitude: lng}})=> {
    const pos = {lat, lng}
     if(typeof(pos)!="undefined"){
       console.log(pos);
       dispatch({type:GET_USER_COORDINATES, payload:pos});
    } 
  }

  const error=(err)=> {
    console.warn(`ERREUR (${err.code}): ${err.message}`);
  }

  const searchProduct = value => {
    const filteredProduct = other_data_product.filter(product => {
      let searchTermLowercase = value.toLowerCase();
      if( product && typeof(product.description)!= 'undefined'){
        let productLowercase = product.description.toLowerCase();
        return productLowercase.indexOf(searchTermLowercase) > -1;
      }
    });
    setDataProduct(filteredProduct); 
  }

  const toggleOpenDrawer=()=>setOpenDrawer(!openDrawer);

  const toggleOpenDetailModal=()=>setOpenDetailModal(!openDetailModal);

  const _choiceCategory=(id_category)=>{
    let _listProduct=stock_product.filter(item=>item.id_category?.indexOf(id_category)>-1);
    setCheckCategory(id_category);
    setDataProduct(_listProduct);
    setOtherDataProduct(_listProduct);
  }

  const addProductToCart=(data)=>{
      let _listProduct=stock_product.map(item=>item.id_stock==data.id_stock?{ ...item, qty:data.qty, isCart:true }: item);
      let _data_product=data_product.map(item=>item.id_stock==data.id_stock?{ ...item, qty:data.qty, isCart:true }: item);
      setDataProduct(_data_product);
      setOtherDataProduct(_data_product); 
      batch(()=>{
           dispatch({type:ADD_CART, payload:[data]});
           dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
           dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
      });
   }

  const increaseProductCart=(id_stock)=>{
        let newCart=cartData.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty+1, price:parseInt(item.prix_vente)*(item.qty+1) }: item);
        let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty+1, isCart:true }: item);
        let _data_product=data_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty+1, isCart:true }: item);
        setDataProduct(_data_product);
        setOtherDataProduct(_data_product);
        batch(()=>{
            dispatch({type:UPDATE_CART, payload:newCart});
            dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
            dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
        });
  }

  const discreaseProductCart = (id_stock,qty) =>{
      if(qty<=1){
        let newCart=cartData.filter(item=>item.id_stock!=id_stock);
        let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, qty:0, isCart:false }: item);
        let _data_product=data_product.map(item=>item.id_stock==id_stock?{ ...item, qty:0, isCart:false }: item);
        setDataProduct(_data_product);
        setOtherDataProduct(_data_product);
        batch(()=>{
            dispatch({type:UPDATE_CART, payload:newCart});
            dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
            dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
        });
      }
      else if(qty>1){
        let newCart=cartData.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty-1, price:parseInt(item.prix_vente)*(item.qty-1) }: item);
        let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty-1, isSelect:true }: item);
        let _data_product=data_product.map(item=>item.id_stock==id_stock?{ ...item, qty:item.qty-1, isSelect:true }: item);
        setDataProduct(_data_product);
        setOtherDataProduct(_data_product);
        batch(()=>{
            dispatch({type:UPDATE_CART, payload:newCart});
            dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
            dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
        });
      }
    }

    const goToCart=()=>history.push("/cart");

    const goToDrawerCart=()=>{
        setOpenDrawer(!openDrawer)
        history.push("/cart");
    }

    const goToDrawerContact=()=>{
        setOpenDrawer(!openDrawer)
        history.push("/contact");
    }

    const goToDrawerOrder=()=>{
        setOpenDrawer(!openDrawer);
        setTypePage("commande");
        if(data_user_store.length>0){
            history.push("/commande");
        }
        else{
            toggleModalDialogue();  
        }
    }

    const _editProductRating=(id_stock)=>{
        setTypePage("rating");
        setIdDisplayProduct(id_stock)
        if(data_user_store.length>0){
            toggleOpenDetailModalRating();
        }
        else{
            toggleModalDialogue();  
        }
    }

    const displayDetailProduct=(id_stock)=>{
        setIdDisplayProduct(id_stock)
        toggleOpenDetailModal();
    }

    const verifConnected=()=>{
        if(typePage=="commande"){
            history.push("/commande");
        }
        else if(typePage=="rating"){
            toggleOpenDetailModalRating();
        }
    }

    const _addProductRating=async(nb_etoile, id_stock)=>{
        setLoading(true);
        let _nb_etoile=parseFloat(nb_etoile/20);
        let tab_rating = await _findUserUserStoreProductRating(data_user_store[0].id_client_store, id_stock);
        if(tab_rating.length<=0){
            let _data_rating={id_user: data_store[0].id_user,id_stock:id_stock,id_store:data_store[0].id_local_store,nb_etoile:_nb_etoile,date_enregistrement:(Date.now()).toString(),id_client_store:data_user_store[0].id_client_store,nom_client_store:data_user_store[0].nom,telephone_client_store:data_user_store[0].numero_telephone,email_client_store:data_user_store[0].email}
            let _id_product_rating=await _saveRating(_data_rating);
            let data_product_rating=await _findUserProductRatingByStockId(id_stock);
            let moy_nb_rating=parseFloat(additionCartMontant(data_product_rating, 0, 'nb_etoile')/data_product_rating.length);
            let data_update_rating={id_stock: id_stock, nb_etoile: moy_nb_rating }
            await _updateProductStockRating(data_update_rating);
            _data_rating.id_product_rating=_id_product_rating;
            let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, nb_etoile:moy_nb_rating }: item);
            setDataProduct(_listProduct);
            batch(()=>{
                dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
                dispatch({type:ADD_USER_STORE_RATING, payload:[_data_rating]});
                dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
            });
            setLoading(false);
        }
    }

    const editProductRating=async(id_product_rating,nb_etoile,id_stock)=>{
        setLoading(true);
        let _nb_etoile=parseFloat(nb_etoile/20);
        let _data_rating={ id_product_rating:id_product_rating, nb_etoile:_nb_etoile, id_stock:id_stock };
        let _id_product_rating=await _updateProductRating(_data_rating);
        let data_product_rating=await _findUserProductRatingByStockId(id_stock);
        let moy_nb_rating=parseFloat(additionCartMontant(data_product_rating, 0, 'nb_etoile')/data_product_rating.length);
        let data_update_rating={id_stock: id_stock, nb_etoile: moy_nb_rating }
        await _updateProductStockRating(data_update_rating);
        let _listProduct=stock_product.map(item=>item.id_stock==id_stock?{ ...item, nb_etoile:moy_nb_rating }: item);
        setDataProduct(_listProduct);
        batch(()=>{
            dispatch({type:UPDATE_STOCK_PRODUCT, payload:_listProduct});
            dispatch({type:REFRESH_VIEW, payload:!_isRefresh});
        });
        setLoading(false);
    }

    const toggleModalDialogue=()=>setOpenModal(!openModal);

    const toggleModalDesktopDialogue=()=>{
        setOpenDetailModal(true);
    }

    const closeModalDesktopDialogue=()=>{
        setOpenDetailModal(false);
    }

    const toggleOpenDetailModalRating=()=>setOpenDetailModalRating(!openDetailModalRating);

    const editListProduct=(value)=>{
        let _listProduct=other_data_product.filter(item=>(parseInt(item.prix_vente)>=parseInt(value[0]) && parseInt(item.prix_vente)<=parseInt(value[1]) ));
        setDataProduct(_listProduct);
    } 

    const _choiceCategoryDesktop=(id_category, tag)=>{
        const _category_product = [...category_product];
        let _listCategory=[];
        let _listProduct=[];
        if(tag=="all" && id_category==0){
            _listCategory = _category_product.map((item)=>{
                return item.id_local_category==0 ? { ...item, isSelect:true, tag:"all" } : { ...item, isSelect:false, tag:"other" }
            });
        }
        else{
            _listCategory = _category_product.map((item)=>{
                return item.id_local_category==id_category ? { ...item, isSelect:!item.isSelect } : item
            }).map((_item)=>{
                return _item.tag=="all" ? { ..._item, isSelect:false } : _item
            });
        }

        let list_tab_category=_listCategory.filter((item)=>{
            return item.isSelect==true
        }).map((item)=>{
            return item.id_local_category
        });

        list_tab_category.forEach((_item)=>{
            _listProduct.push(...stock_product.filter(item=>item.id_category?.indexOf(_item)>-1));
        })

        setCheckCategory(id_category);
        setDataProduct(_listProduct);
        setOtherDataProduct(_listProduct);
        setCategoryProduct(_listCategory);
    }

    const handleChoiceMenu=(id)=>{
        let _data_product = [...data_product];
        let name_filter=array_filter_menu.find(item=>item.id==id);
        setFilterPage(name_filter.name_cat);
        if(id==1){
            _data_product=_data_product.sort((a,b)=>{
                return b.prix_vente - a.prix_vente
            });
        }
        else if(id==2){
            _data_product=_data_product.sort((a,b)=>{
                return a.prix_vente - b.prix_vente
            });
        }
        else if(id==3){
            _data_product=_data_product.sort((a,b)=>{
                return b.nb_etoile - a.nb_etoile
            });
        }
        setDataProduct(_data_product);
        setOtherDataProduct(_data_product);
        handleClose();
    }
     
  return (
    <>
        {
        isMobile ?  
        (
            <>
            <header className='headerMobile'  >
            <div className='searchNavBar' >
                <div onClick={()=>toggleOpenDrawer()} style={{     marginTop:3 }} ><span><MenuIcon  style={{ color:"#222", fontSize:26 }}  /></span></div>
                <div><span style={{ fontFamily:"NunitoSans-Bold", color:"#222", fontSize:16}} >{data_store[0].nom}</span></div>
                <div onClick={goToCart} style={{ marginTop:3, position:"relative" }} >
                    <span style={{ position:"absolute", top:-5, right:-7,  width:20, height:20, borderRadius:20, backgroundColor:"#30d158", color:"#fff", fontSize:9, display:"flex", alignItems:"center", justifyContent:"center", fontFamily:"NunitoSans-Bold" }} >{cartData.length}</span>
                    <ShoppingCartOutlinedIcon  style={{ color:"#222", fontSize:26 }}  />
                </div>
            </div>
            <div className='headerNavTabBar' >
                {
                    category_product.length>0 && category_product.map((item,index)=>{
                        return(
                            <React.Fragment key={item.id_local_category} >
                                {checkCategory === item.id_local_category && (
                                    <div style={{  padding:"13px 14px", marginLeft:5, marginRight:5, backgroundColor:item.color_category, borderRadius:30 }} >
                                        <div><span style={{ fontSize:11, fontFamily:"NunitoSans-Bold", color:"#fff", whiteSpace:"nowrap" }}  > {item.nom_category} </span></div>
                                    </div>)}
                                {checkCategory != item.id_local_category && (
                                    <div onClick={() => _choiceCategory(item.id_local_category)} style={{   padding:"13px 14px", marginLeft:5, marginRight:5, backgroundColor:"#f0f0f0", borderRadius:30 }} >
                                        <div><span style={{  fontSize:11, fontFamily:"NunitoSans-Bold", color:"#222", whiteSpace:"nowrap" }}  > {item.nom_category} </span></div>
                                    </div>)}
                            </React.Fragment>
                        )
                    })
                }
            </div> 
            <div style={{  position:"relative", marginLeft:15, marginRight:15, }} >
                <Search size={18} style={{ position:"absolute", right:15, top:13, color:"#bbb" }}  />
                <input
                    type="text"
                    style={{border: "1px solid #f2f2f2",background: "#f2f2f2",width: "100%",height:45,boxSizing: "border-box",borderRadius:60,color: "#333",fontSize:12,outline: "none",overflow: "hidden",zIndex: 1,paddingRight:35,paddingLeft:15, fontFamily:"NunitoSans-Bold" }}
                    placeholder="Rechercher un produit..."
                    onChange={e=>searchProduct(e.target.value)}
                />
            </div>  
            <SliderPrice data_product={other_data_product} checkCategory={checkCategory} editListProduct={editListProduct} />
        </header>
        <div className='containProduct'  > 
        <Grid container spacing={0}>
            {
                data_product.length>0 && data_product.map((item,index)=>{
                    return(
                       <ItemProduct key={item.id_stock} qty={item.qty} isCart={item.isCart} icone_product={item.icone_product} description={item.description} prix_vente={item.prix_vente} quantite={item.quantite} unite_vente={item.unite_vente} id_stock={item.id_stock} is_new_stock={item.is_new_stock} quatity_security={item.quatity_security} addProductToCart={addProductToCart} increaseProductCart={increaseProductCart} discreaseProductCart={discreaseProductCart} id_product={item.id_product} other_index={item.other_index} displayDetailProduct={displayDetailProduct} _index={index} _editProductRating={_editProductRating}  nb_etoile={item.nb_etoile} /> 
                    )
                })
            }
        </Grid>
        {
            cartData.length>0 && (
                <div onClick={goToCart} style={{ position:"fixed", bottom:10, right:0, width:window.innerWidth-50, height:50, background:"#30d158", borderRadius:20, marginRight:25, boxShadow:"rgba(0, 0, 0, 0.1) 0px 1px 2px 0px", display:"flex", alignItems:"center", justifyContent:"space-between*" }} >
                    <div style={{ flex:1, display:"flex", alignItems:"center", justifyContent:"center" }} > <ShoppingBasketIcon style={{ fontSize:20, color:"#fff"  }} /> <div style={{marginLeft:5, marginTop:0}} > <span style={{ fontFamily:"NunitoSans-Bold", color:"#fff", fontSize:11,  }} > {cartData.length} produit(s) </span> </div> </div>
                    <div style={{ flex:1, display:"flex", alignItems:"center", justifyContent:"center" }} > <div style={{ marginTop:2 }} > <span style={{ fontFamily:"NunitoSans-Bold", color:"#fff", fontSize:11,  }} > Mon pannier </span> </div> </div>
                    <div style={{ flex:1, display:"flex", alignItems:"center", justifyContent:"center" }} > <div style={{ marginTop:2 }} > <span style={{ fontFamily:"NunitoSans-Bold", color:"#fff", fontSize:11,  }} > {formatNumber(additionCartMontant(cartData, 0, 'price'))} FCFA </span> </div> </div>
                </div>
            )
        }
        </div>
            </>
        ) : 
            <div style={{  display:"flex", flex:1, flexDirection:"row", marginTop:15  }} >
                <div style={{ width:25+"%",  }} >
                <div style={{ backgroundColor:"#fff", padding:"10px 0px", }} >
                    <div>
                        <SliderPrice data_product={other_data_product} checkCategory={checkCategory} editListProduct={editListProduct} color={data_store[0].color}  />
                    </div>
                    <h4 style={{  padding:"13px 18px", background:"#F5F5F5", borderRadius:"8px 8px 0px 0px", marginLeft:10, marginRight:10  }}  >Filter Categories</h4>
                    <div style={{ marginTop:-21, padding:18, borderRadius:"0px 0px 10px 10px", border:"1px solid #ebebeb", marginLeft:10, marginRight:10 }} >
                        {
                            category_product.length>0 && category_product.map((item,index)=>{
                                return(
                                    <CategoryItemDesktop key={index+1202020}  color={data_store[0].color}  nom_category={item.nom_category} id_local_category={item.id_local_category}  isSelect={item.isSelect} tag={item.tag} _choiceCategoryDesktop={_choiceCategoryDesktop}  />
                                )
                            })
                        }
                    </div>
                </div>
                </div>
                <div style={{ flex:1, marginLeft:15, marginRight:0, background:"#fff", paddingLeft:10, paddingRight:10,  }} >
                    
                    <div style={{ marginTop:15, width:"100%", display:"flex", flexDirection:"row", alignItems:"center", justifyContent:"space-between" }} >
                        <div style={{ marginLeft:10 }} >
                            <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:15 }} >Liste des produits:   1–{data_product.length} Produit(s)</span>
                        </div>
                        <div onClick={handleClick} id="demo-positioned-button" aria-controls={open ? 'demo-positioned-menu' : undefined}  aria-haspopup="true"  aria-expanded={open ? 'true' : undefined}  style={{  backgroundColor:"#F9F9F9",border:"1px solid rgba(1, 15, 28, 0.1)", fontSize: 14,color:"#010F1C",height: 40, padding:"0 15px",width: 150, display:"flex", alignItems:"center", justifyContent:"space-between", marginRight:10, borderRadius:5, cursor:"pointer" }} tabindex="0">
                            <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:13 }}  >{filterPage}</span>
                            <KeyboardArrowDownIcon style={{ color:"#000", fontSize:24, }}  />
                        </div>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={()=>handleChoiceMenu(1)}><div style={{ width:150 }} ><span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:12, }}  >Le prix le plus élevé</span></div></MenuItem>
                            <MenuItem onClick={()=>handleChoiceMenu(2)}><div style={{ width:150 }} ><span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:12 }}  >Le prix le plus bas</span></div></MenuItem>
                            <MenuItem onClick={()=>handleChoiceMenu(3)}><div style={{ width:150 }} ><span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:12 }}  >Les mieux notés</span></div></MenuItem>
                        </Menu>
                    </div>

                    <div style={{ marginTop:20, background:"#fff", height:"85%", overflow:"scroll"  }} >
                    <Grid container spacing={0}>
                        {
                            data_product.length>0 && data_product.map((item,index)=>{
                                return(
                                    <ItemProductDesktop key={item.id_stock}  qty={item.qty} isCart={item.isCart} icone_product={item.icone_product} description={item.description} prix_vente={item.prix_vente} quantite={item.quantite} unite_vente={item.unite_vente} id_stock={item.id_stock} is_new_stock={item.is_new_stock} quatity_security={item.quatity_security} addProductToCart={addProductToCart} increaseProductCart={increaseProductCart} discreaseProductCart={discreaseProductCart} id_product={item.id_product} other_index={item.other_index} displayDetailProduct={displayDetailProduct} _index={index} _editProductRating={_editProductRating}  nb_etoile={item.nb_etoile} /> 
                                )
                            })
                        }
                    </Grid>
                    </div>
                    
                </div>
            </div>
         }
         
        {
            loading && (
                <div style={{ position:"absolute", top:0, left:0, right:0, bottom:0, backgroundColor:"rgba(255, 255, 255, 0.5)", display:"flex", alignItems:"center", justifyContent:"center" }} >
                    <CircularProgress style={{ color:"#30d158" }} />
                </div>
            )
        }
        {
            (openModal && isMobile) && <ModalLogin  openModal={openModal} toggleModalDialogue={toggleModalDialogue} goToCheckout={verifConnected}  />
        }
        {
            (openModal && !isMobile) && <ModalDesktopLogin  openModal={openModal} toggleModalDialogue={toggleModalDialogue} goToCheckout={verifConnected}  />
        }
        <DrawerNavigator openDrawer={openDrawer} toggleOpenDrawer={toggleOpenDrawer} goToDrawerCart={goToDrawerCart} goToDrawerOrder={goToDrawerOrder} goToDrawerContact={goToDrawerContact} />
        {
            (openDetailModal && id_display_product>0 && isMobile) && <ModalDetailProduct openDetailModal={openDetailModal} toggleOpenDetailModal={toggleOpenDetailModal} id_display_product={id_display_product} increaseProductCart={increaseProductCart} addProductToCart={addProductToCart}  discreaseProductCart={discreaseProductCart} /> 
        }
        {
            (openDetailModal && id_display_product>0 && !isMobile) && <ModalDetailDesktopProduct openDetailModal={openDetailModal} color={data_store[0].color} toggleOpenDetailModal={toggleModalDesktopDialogue}  closeModalDesktopDialogue={closeModalDesktopDialogue}  id_display_product={id_display_product} increaseProductCart={increaseProductCart} addProductToCart={addProductToCart}  discreaseProductCart={discreaseProductCart} _editProductRating={_editProductRating} /> 
        }
        {
            (openDetailModalRating && id_display_product>0 && isMobile) && <ModalRating openDetailModalRating={openDetailModalRating} toggleOpenDetailModalRating={toggleOpenDetailModalRating}  id_display_product={id_display_product} _addProductRating={_addProductRating}  editProductRating={editProductRating}  />
        }
        {
            (openDetailModalRating && id_display_product>0 && !isMobile) && <ModalDesktopRating color={data_store[0].color} openDetailModalRating={openDetailModalRating} toggleOpenDetailModalRating={toggleOpenDetailModalRating}  id_display_product={id_display_product} _addProductRating={_addProductRating}  editProductRating={editProductRating}  />
        }
    </>
  )
}

export default Home