import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ItemCommande from "../components/ItemCommande";
import ItemCommandeDesktop from "../components/ItemCommandeDesktop";
import DetailListOrderDesktop from "../components/DetailListOrderDesktop";
import CircularProgress from '@mui/material/CircularProgress';
// import PersonIcon from '@mui/icons-material/Person';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import { _findCommandeByClientStoreId } from "../functions/requestCommande";
import { useScreenDetector } from "../hooks/useMediaQuery";
import remove from "../assets/images/remove.png";
import empty_cart from "../assets/images/empty_cart.png";

const Commande = () => {

  const history=useHistory();
  const data_user_store=[...useSelector(state => state.list_user_store)];
  const data_store=[...useSelector(state => state.store_data)];
  const [dataCommande, setDataCommande]=useState([]);
  const [allDataCommande, setAllDataCommande]=useState([]);
  const [detail_commande, setDetailCommande]=useState(null);
  const [id_commande, setIdCommande]=useState(0);
  const [status_commande, setStatusCommande]=useState(0);
  const [loader, setLoader]=useState(false);
  const [openDrawer, setOpenDrawer]=useState(false);
  const [type_order, setTypeOrder]=useState(0);
  const { isMobile, isTablet, isDesktop } = useScreenDetector();

  useEffect( () => {
    async function selectAllCommandeData(){
        setLoader(true);
        let _data_commande=await _findCommandeByClientStoreId(data_user_store[0].id_client_store.toString());
        let tab_commande = _data_commande.filter((item)=>{
            return item.status==0;
        });
        setAllDataCommande(_data_commande);
        setDataCommande(tab_commande);
        setLoader(false);
    }
    selectAllCommandeData();
    }, []); 

    const toggleDrawer=()=>{
        setOpenDrawer(true);
    }

    const closeDrawer=()=>{
        setOpenDrawer(false);
    }

    const _goBack=()=>history.goBack();

    const goToDetailCommande=(id_commande, status)=>{
        if(isMobile){
            let _data_commande=dataCommande.find(item=>id_commande==item.id_commande);
        //    setDetailCommande(_data_commande);
        //    setIdCommande(id_commande);
        //    setStatusCommande(status);
            history.push("/detail_commande", { id_commande, _data_commande, status });
        }
        else if(!isMobile){
            setOpenDrawer(true);
            let _data_commande=dataCommande.find(item=>id_commande==item.id_commande);
            setDetailCommande(_data_commande);
            setIdCommande(id_commande);
            setStatusCommande(status);
        }
    }

    const _setTypeOrder=(id)=>{
        let _allDataCommande = [...allDataCommande];
        let tab_commande = [];
        if(id==0){
            tab_commande = _allDataCommande.filter((item)=>{
                return item.status==0;
            });
        }
        else if(id==1){
            tab_commande = _allDataCommande.filter((item)=>{
                return item.status==1;
            });
        }
        else if(id==-1){
            tab_commande = _allDataCommande.filter((item)=>{
                return item.status==-1;
            });
        }
        setTypeOrder(id);
        setDataCommande(tab_commande);
    }

  return (
    <>
        {
            isMobile ?  
            (
                <>

                    <header className='headerMobile' >
                    <div className='searchNavBar' >
                        <div onClick={()=>_goBack()}  style={{  marginTop:3, marginLeft:-5 }} ><span><ArrowBackIcon  style={{ color:"#222", fontSize:26 }}  /></span></div>
                        <div><span style={{ fontFamily:"NunitoSans-Bold", color:"#222", fontSize:16}} >Mes commandes</span></div>
                        <div  style={{ marginTop:3, position:"relative", width:40 }} >

                        </div>
                    </div>
                    </header>
                    <div className='containCart' style={{ marginTop:-12, backgroundColor:"#f5f5f5" }} >
                        {
                            loader ? (
                                <div style={{ display:"flex", height:100+"vh", width:"100%", alignItems:"center", justifyContent:'center' }} >
                                    <CircularProgress style={{ color:"#30d158", marginTop:-100 }} />
                                </div>
                            ):(
                            <div>
                                {
                                    dataCommande.length>0 && dataCommande.sort((a, b)=>(b.date_enregistrement-a.date_enregistrement)).map((item,index)=>{
                                        return(
                                            <ItemCommande key={item.id_commande} status={item.status} id_commande={item.id_commande} number_commande={item.number_commande} nb_article={item.nb_article} montant={item.montant} date_enregistrement={item.date_enregistrement} goToDetailCommande={goToDetailCommande} />
                                        )
                                    })
                                }
                            </div>
                            )
                        }
                    </div>
                
                </>
            ):(

                <div style={{ padding:"15px 20px", display:"flex", flex:1, flexDirection:"row", marginTop:0  }} >
                <div style={{ width:27+"%" }} >

                    <div style={{ backgroundColor:"#fff", padding:"10px 0px", }} >
                        {
                            type_order==0 && (
                                <div  onClick={()=>_setTypeOrder(0)}  style={{ display:"flex", cursor:"pointer", flexDirection:"row", padding:"10px 30px", background:`${data_store[0].color}2a`,   alignItems:"center",  borderRadius:0 }} >
                                    <Inventory2OutlinedIcon style={{ color:"#000", fontSize:22, marginRight:10 }}  />
                                    <span style={{ color:"#000", fontFamily:"NunitoSans-Bold", fontSize:13 }}  >Commande en attente</span>
                                </div>
                            )
                        }
                        {
                            type_order!=0 && (
                                <div  onClick={()=>_setTypeOrder(0)}  style={{ display:"flex", cursor:"pointer", flexDirection:"row", padding:"10px 30px", background:"#fff",   alignItems:"center",  borderRadius:0 }} >
                                    <Inventory2OutlinedIcon style={{ color:"#aaa", fontSize:22, marginRight:10 }}  />
                                    <span style={{ color:"#aaa", fontFamily:"NunitoSans-Bold", fontSize:13 }}  >Commande en attente</span>
                                </div>
                            )
                        }
                        {
                            type_order==1 && (
                                <div  onClick={()=>_setTypeOrder(1)}  style={{ display:"flex", cursor:"pointer", flexDirection:"row", padding:"10px 30px", background:`${data_store[0].color}2a`,   alignItems:"center",  borderRadius:0 }} >
                                    <UnarchiveOutlinedIcon style={{ color:"#000", fontSize:22, marginRight:10 }}  />
                                    <span style={{ color:"#000", fontFamily:"NunitoSans-Bold", fontSize:13 }}  >Commande payé</span>
                                </div>
                            )
                        }
                        {
                            type_order!=1 && (
                                <div onClick={()=>_setTypeOrder(1)}  style={{ display:"flex", cursor:"pointer", flexDirection:"row", padding:"10px 30px", background:"#fff",   alignItems:"center",  borderRadius:0 }} >
                                    <UnarchiveOutlinedIcon style={{ color:"#aaa", fontSize:22, marginRight:10 }}  />
                                    <span style={{ color:"#aaa", fontFamily:"NunitoSans-Bold", fontSize:13 }}  >Commande payé</span>
                                </div>
                            )
                        }
                        {
                            type_order==-1 && (
                                <div onClick={()=>_setTypeOrder(-1)} style={{ display:"flex", cursor:"pointer", flexDirection:"row", padding:"10px 30px", background:`${data_store[0].color}2a`,   alignItems:"center",  borderRadius:0 }} >
                                    <ArchiveOutlinedIcon style={{ color:"#000", fontSize:22, marginRight:10 }}  />
                                    <span style={{ color:"#000", fontFamily:"NunitoSans-Bold", fontSize:13 }}  >Commande Annulée</span>
                                </div>
                            )
                        }
                        {
                            type_order!=-1 && (
                                <div  onClick={()=>_setTypeOrder(-1)} style={{ display:"flex", cursor:"pointer", flexDirection:"row", padding:"10px 30px", background:"#fff",   alignItems:"center",  borderRadius:0 }} >
                                    <ArchiveOutlinedIcon style={{ color:"#aaa", fontSize:22, marginRight:10 }}  />
                                    <span style={{ color:"#aaa", fontFamily:"NunitoSans-Bold", fontSize:13 }}  >Commande Annulée</span>
                                </div>
                            )
                        }
                    </div>
                    
                </div>
                <div style={{ flex:1, marginLeft:10, marginRight:10, boxShadow:"0 2px 5px 0 rgba(0, 0, 0, .05)",  marginBottom:15, background:"#fff",  }} >
                <div style={{ padding:"10px 20px", borderBottom:"solid 1px #ececec" }} >
                    <span style={{ fontFamily:"NunitoSans-Bold", color:"#000", fontSize:14,  }} >LISTE DE VOS COMMANDES</span>
                </div>
                <div style={{ padding:"10px 12px", marginLeft:5, marginRight:5, borderRadius:4  }} >
                {
                loader ? (
                    <div style={{ display:"flex", height:100+"vh", width:"100%", alignItems:"center", justifyContent:'center' }} >
                        <CircularProgress style={{ color:data_store[0].color, marginTop:-100 }} />
                    </div>
                ):(
                    <div style={{ background:"#fff",  height:500, overflow:"scroll" }} >
                        {
                            dataCommande.length>0 && dataCommande.sort((a, b)=>(b.date_enregistrement-a.date_enregistrement)).map((item,index)=>{
                                return(
                                    <ItemCommandeDesktop key={item.id_commande} color={data_store[0].color} status={item.status} id_commande={item.id_commande} number_commande={item.number_commande} nb_article={item.nb_article} montant={item.montant} date_enregistrement={item.date_enregistrement} goToDetailCommande={goToDetailCommande} />
                                )
                            })
                        }
                        {
                            dataCommande.length<=0 && (
                                <div style={{ flex:1, textAlign:"center" }} >
                                    <img src={empty_cart} alt='images' style={{ width:200, height:200, marginTop:80, objectFit:"contain" }}  />
                                </div>
                            )
                        }
                    </div>
                  )
                }
                </div>

                </div>
                </div>  
            )
        }
        {
            (!isMobile && openDrawer) && <DetailListOrderDesktop color={data_store[0].color}  open={openDrawer} _toggleDrawer={toggleDrawer}  closeDrawer={closeDrawer} detail_commande={detail_commande} status_commande={status_commande} />
        }
    </>
  )
}

export default Commande;